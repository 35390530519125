
import { mapGetters, mapMutations, mapState } from 'vuex';
import {
  listOfPackages,
  packageIntegration,
  partners
} from '@/util/mockData/marketPlace';

export default {
  data() {
    return {
      show: false,
      tab: 'overview',
      slide: 1,
      fullscreen: false,
      optionsList: [
        { label: 'Paid', value: 'paid' },
        { label: 'Free', value: 'free' }
      ],
      optionSelected: [],
      tabIndex: 1,
      splitterModel: 20,
      vendors: ['HikVision', 'NCR', 'FILR'],
      tags: ['People Counting', 'POS', 'WIFI'],
      details: {}
    };
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    ...mapState('marketplace', ['selectedTag', 'pricing']),
    marketType() {
      return this.$route.params.marketType;
    },
    typeId() {
      return this.$route.params.detail;
    },
    locale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    async optionSelected() {
      await this.setPricing([...this.optionSelected]);
    }
  },
  created() {
    if (this.marketType === 'packages') {
      this.details = listOfPackages.filter(v => v.id === this.typeId)[0];
    }
    if (this.marketType === 'partners') {
      this.details = partners.filter(v => v.id === this.typeId)[0];
    }
    if (this.marketType === 'integrations') {
      this.details = packageIntegration.filter(v => v.id === this.typeId)[0];
    }
  },
  methods: {
    ...mapMutations('marketplace', ['setSelectedTag', 'setPricing']),
    openDialog(int) {
      this.detailsSelected = int;
      this.show = true;
    },
    closeModal() {
      this.show = false;
    },
    getImgUrl(pic) {
      return require('../assets/img/icons/' + pic + '.png');
    },
    onLinkClick(details) {
      gtag('event', 'Open-Link', {
        event_action: 'Partner Link Click',
        event_category: 'Partner Link',
        event_label: details.name,
        value: 1
      });
    }
  }
};
